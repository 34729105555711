import React, { useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Divider, IconButton, Typography, Tabs, Tab, MenuItem, ListItemText } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import services from "../../../services";
import PageTitle from "../../../common/PageTitle";
import EventCard from "../../../components/Events/EventCards/EventCard";
import { setCommunityPartners, setEventData } from "../../../modules/actions";
import { USER_WITH_TICK } from "../../../constants";
import EventDetailsCard from "../../../components/Events/EventDetailsCard";
import AppliedStartups from "./AppliedStartups";
import AddEvent from "./AddEvent";
import { AbilityContext, Can } from "../../../services/abilities/context";
import { useAbility } from "@casl/react";
import UpdatedSelectInput from "../../../common/UpdatedSelectInput";

const EventDashboard = () => {
	const [eventList, setEventList] = useState([]);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [selectedSlide, setSelectedSlide] = useState(0);
	const [openNewEvent, setOpenNewEvent] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	const { partnerCode } = useSelector((store) => store?.auth);

	const communityPartnersOptions = useSelector((state) => state?.communityPartners?.communityPartners);
	const selectedOption = useSelector((state) => state?.communityPartners?.selectedOption);
	const communityPartnerItems = useSelector((state) => state?.communityPartners?.communityPartnerItems);
	const [selectedSort, setSelectedSort] = useState(selectedOption);
	const [menuOpen, setMenuOpen] = useState(false);
	const selectedPartnerCode = communityPartnerItems?.find((item) => item?.partnerName == selectedOption);
	const ability = useAbility(AbilityContext);

	const showCommunityPartners = ability.can("admin", "EventDashboard");

	const fetchOptions = async () => {
		try {
			const response = await services.getCommunityPartnersApi("founders");

			if (!ability.can("admin", "CommunityEventDashboard")) {
				dispatch(setCommunityPartners({ showCommunityPartners: false, selectedOption: "", communityPartners: [] }));
				return;
			} else if (!response?.data?.data || response?.data?.data?.length === 0) {
				dispatch(setCommunityPartners({ showCommunityPartners: false, selectedOption: "", communityPartners: [] }));
				return;
			} else {
				const resData = response?.data?.data;
				const communityPartnerNames = resData && resData.length > 0 && resData?.map((item) => item?.partnerName);
				const communityPartnerItems = resData && resData.length > 0 && resData?.map((item) => item);

				communityPartnerNames.push("StartupOS");
				communityPartnerItems.push({
					partnerName: "StartupOS",
					partnerCode: "sos",
				});

				dispatch(
					setCommunityPartners({
						showCommunityPartners: true,
						communityPartners: communityPartnerNames,
						communityPartnerItems: communityPartnerItems,
					})
				);
			}
		} catch (error) {
			console.error("Error fetching options:", error);
		}
	};

	const EVENT_TABS = [
		{ id: 0, title: "Upcoming Events" },
		{ id: 1, title: "Past Events" },
	];
	const [currentTab, setCurrentTab] = useState(0);
	const BREAK_POINTS = {
		120: {
			slidesPerView: 1,
			spaceBetween: 10,
		},

		712: {
			slidesPerView: 2,
			spaceBetween: 10,
		},
		1031: {
			slidesPerView: 2,
			spaceBetween: 10,
		},
		1336: {
			slidesPerView: 3,
			spaceBetween: 10,
		},
		1505: {
			slidesPerView: 3,
			spaceBetween: 10,
		},
		1705: {
			slidesPerView: 4,
			spaceBetween: 10,
		},
	};

	const ITEM_HEIGHT = 30;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
				borderRadius: "0px 0px 40px 40px",
				boxShadow: "0px 5px 24px rgba(0, 0, 0, 0.15)",
			},
		},
	};
	const handleChange = (event) => {
		dispatch(setCommunityPartners({ selectedOption: event?.target?.value }));
		setSelectedSort(event?.target.value);
	};

	const handleMenuOpen = (value) => {
		setMenuOpen(value);
	};

	useEffect(() => {
		getEventList();
		fetchOptions();
	}, []);
	useEffect(() => {
		getEventList();
	}, [selectedSort]);

	const handleTabChange = (event, newValue) => {
		setCurrentTab(newValue);
		setSelectedSlide(0);
	};
	const isPastEvent = (eventDate) => {
		const currentDate = new Date().getTime();
		let check = new Date(eventDate);
		if (check < currentDate) {
			return true;
		} else {
			return false;
		}
	};

	const getEventList = async () => {
		try {
			setLoading(true);
			const response = await services.getEventListApi(
				"all",
				!ability.can("admin", "CommunityEventDashboard") ? (partnerCode ? partnerCode : "sos") : selectedPartnerCode?.partnerCode
			);
			if (response) {
				setLoading(false);
				dispatch(
					setEventData({
						eventList: response?.data?.data,
					})
				);
			}

			setEventList(response?.data?.data);
		} catch (error) {
			console.error("Error fetching event list:", error);
		}
	};

	const handleEventClick = (index) => {
		setSelectedSlide(index);
	};
	const openUpdateEventModel = () => {
		setIsEdit(true);
		setOpenNewEvent(true);
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Event Dashboard</title>
			</Helmet>
			<Box
				sx={{
					px: {
						lg: 8,
						md: 4,
						xs: 2,
					},
					pb: "30px",
					display: "flex",
					flexDirection: "column",
					gap: "32px",
					maxWidth: "1920px",
					margin: "0 auto",
				}}
			>
				<Box display="flex" alignItems="center">
					{showCommunityPartners && (
						<Box width={"180px"} ml={2}>
							<UpdatedSelectInput
								onInputChange={handleChange}
								MenuProps={MenuProps}
								selectedValue={selectedSort}
								handleMenuOpen={handleMenuOpen}
								handleMenuClose={handleMenuOpen}
								wrapperstyle={{
									"& .MuiInputBase-root": {
										height: "auto",
										boxShadow: "0px 3px 11px rgba(0, 0, 0, 0.05)",
										borderRadius: menuOpen ? "40px 40px 0px 0px" : "40px",
										fontSize: "16px",
										fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
										background: (theme) => theme.palette.secondary.white,
										width: "216px",
									},
									"& .MuiOutlinedInput-notchedOutline": {
										border: "none",
									},
									"& .MuiOutlinedInput-input": { pl: 3 },
									width: 1,
								}}
								renderValue={(selected) => {
									if (!selected?.length > 0) {
										return (
											<Box sx={{ my: 0, py: 0 }} display={"flex"}>
												<Typography
													variant="poppinsMedium16"
													sx={{
														lineHeight: "19px",
														textOverflow: "ellipsis",
														whiteSpace: "nowrap",
														overflow: "hidden",
													}}
													color="secondary.black"
												>
													Select Community
												</Typography>
											</Box>
										);
									}

									return (
										<Box sx={{ display: "flex" }} alignItems="center">
											<Typography
												variant="poppinsMedium16"
												sx={{
													lineHeight: "19px",
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													overflow: "hidden",
												}}
												color="secondary.black"
											>
												{selected}
											</Typography>{" "}
										</Box>
									);
								}}
								inputProps={{ "aria-label": "Without label" }}
							>
								{communityPartnersOptions?.length > 0 &&
									communityPartnersOptions?.map((option) => (
										<MenuItem
											display={"flex"}
											alignItems="center"
											key={option}
											value={option}
											sx={{
												py: 1,
												px: 2,
												"&.Mui-selected": {
													backgroundColor: "rgba(106, 117, 117, 0.08)",
												},
											}}
											onClick={() => {}}
										>
											<ListItemText sx={{ ml: 3 }} primary={<Typography variant="custom070">{option}</Typography>} />{" "}
										</MenuItem>
									))}
							</UpdatedSelectInput>
						</Box>
					)}
				</Box>

				<PageTitle icon={USER_WITH_TICK} label={"StartupOS Admin Board"} />
				<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
					<Box sx={{ backgroundColor: "#000000", borderRadius: "100px", maxWidth: "fit-content", p: "12px 20px 12px 20px" }}>
						<Typography variant="Text/sm/Semibold" sx={{ color: "white" }}>
							Events Management Dashboard
						</Typography>
					</Box>
					<Can I="manage" a="EventDashboard">
						<Button
							startIcon={<AddRoundedIcon />}
							onClick={() => {
								setOpenNewEvent(true);
								setIsEdit(false);
							}}
							variant="DS1"
						>
							New Event
						</Button>
					</Can>
				</Box>
				<Box display={"flex"} justifyContent={"space-between"}>
					{" "}
					<Tabs
						variant="scrollable"
						scrollButtons="auto"
						allowScrollButtonsMobile
						value={currentTab}
						onChange={handleTabChange}
						sx={{
							alignItems: "stretch",
							"& .MuiTabs-flexContainer": {
								gap: 5,
							},
							"& .MuiTabs-indicator": {
								backgroundColor: (theme) => theme.palette.primary.main,
								borderRadius: "5px",
								height: "4px",
							},
							"& .MuiTabs-scrollButtons": {
								color: (theme) => theme.palette.primary.main,
								"& > svg": {
									height: "30px",
									width: "30px",
								},
							},
						}}
					>
						{EVENT_TABS.map((item, index) => {
							const tabItem = (
								<Tab
									selected={currentTab === item.id}
									onClick={(event) => {
										handleTabChange(event, index);
									}}
									disableRipple
									key={item.id}
									sx={{
										textTransform: "unset",
										textAlign: "left",
										p: 0,
										pb: 1,
										whiteSpace: "nowrap",
										maxWidth: "unset",
										opacity: ".6",
										filter: "grayscale(100%)",
										"&.Mui-selected": {
											opacity: 1,
											filter: "grayscale(0%)",
										},
									}}
									label={
										<Box display={"flex"} alignItems={"center"} gap={1}>
											<Typography
												variant="Text/xs/Semibold"
												sx={{
													color: (theme) => theme.palette.primary.main,
												}}
											>
												{item.title}
											</Typography>
											{currentTab === item.id && (
												<Typography
													variant="Text/xs/Medium"
													sx={{
														color: (theme) => theme.palette.primary.main,
														backgroundColor: (theme) => theme.palette.primary.light,
														borderRadius: "50%",
														p: 1,
														height: "10px",
														width: "10px",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}
												>
													{eventList?.filter((item) =>
														currentTab === 0 ? !isPastEvent(item.eventDate) : isPastEvent(item.eventDate)
													)?.length || 0}
												</Typography>
											)}
										</Box>
									}
								/>
							);
							return <Fragment key={index}>{tabItem}</Fragment>;
						})}
					</Tabs>
				</Box>
				{/* <EventsSection events={eventList?.filter((item) => (currentTab === 0 ? !isPastEvent(item.deadlineDate) : isPastEvent(item.deadlineDate)))} /> */}
				{
					eventList?.filter((item) => (currentTab === 0 ? !isPastEvent(item.eventDate) : isPastEvent(item.eventDate)))?.length > 0 && (
						<Box sx={{ position: "relative" }}>
							<IconButton
								sx={{ position: "absolute", left: 0, zIndex: 1, top: "50%", background: "#F2F4F7" }}
								className="custom-swiper-button-prev"
							>
								<ArrowBackRoundedIcon sx={{ color: "#344054" }} />
							</IconButton>
							<IconButton
								sx={{ position: "absolute", right: 0, zIndex: 1, top: "50%", background: "#F2F4F7" }}
								className="custom-swiper-button-next"
							>
								<ArrowForwardRoundedIcon sx={{ color: "#344054" }} />
							</IconButton>
							<Box px={6}>
								<Swiper
									breakpoints={BREAK_POINTS}
									allowTouchMove={false}
									spaceBetween={30}
									slidesPerView={4}
									grabCursor={false}
									initialSlide={0}
									rewind={false}
									navigation={{
										prevEl: ".custom-swiper-button-prev",
										nextEl: ".custom-swiper-button-next",
									}}
									loop={false}
									centeredSlides={false}
									fade={true}
									modules={[Pagination, Navigation]}
								>
									{eventList
										?.filter((item) => (currentTab === 0 ? !isPastEvent(item.eventDate) : isPastEvent(item.eventDate)))
										.map((slideContent, index) => (
											<SwiperSlide key={index}>
												<Box key={index} display={"flex"} gap={20}>
													<EventCard
														key={index}
														eventName={slideContent.title}
														eventDescription={slideContent.description}
														imageSrc={slideContent.imageUrl}
														isPastEvent={false}
														isPremium={slideContent?.isPremiumEvent}
														displayDate={slideContent?.displayDate}
														onEventClick={() => handleEventClick(index)}
														showActions={false}
														boxShadow="none"
														isSelected={index === selectedSlide}
													/>{" "}
												</Box>
											</SwiperSlide>
										))}
								</Swiper>
							</Box>
						</Box>
					)
					//  : (
					// 	<Typography variant="Text/md/Semibold" sx={{ textAlign: "center", mt: 3 }} component={"p"}>
					// 		No Events Found
					// 	</Typography>
					// )
				}
				{/* {eventList?.filter((item) => (currentTab === 0 ? !isPastEvent(item.deadlineDate) : isPastEvent(item.deadlineDate)))?.length > 0 && ( */}
				<>
					{eventList?.filter((item) => (currentTab === 0 ? !isPastEvent(item.eventDate) : isPastEvent(item.eventDate)))?.length > 0 && (
						<Box display={"flex"} alignItems={"center"} gap={2}>
							<Typography variant="Text/xl/Semibold">Event</Typography>
							<Divider sx={{ flexGrow: 1 }} />
						</Box>
					)}
					<EventDetailsCard
						eventDetails={
							eventList
								?.filter((item) => (currentTab === 0 ? !isPastEvent(item.eventDate) : isPastEvent(item.eventDate)))
								.filter((item, idx) => idx === selectedSlide)?.[0]
						}
						loading={loading}
						isEdit={true}
						openUpdateEventModel={openUpdateEventModel}
					/>
				</>
				<Can I="view" a="EventApplicants">
					{eventList?.filter((item) => (currentTab === 0 ? !isPastEvent(item.eventDate) : isPastEvent(item.eventDate)))?.length > 0 && (
						<AppliedStartups
							eventList={eventList?.filter((item) => (currentTab === 0 ? !isPastEvent(item.eventDate) : isPastEvent(item.eventDate)))}
							selectedSlide={selectedSlide}
						/>
					)}
				</Can>
				<AddEvent
					open={openNewEvent}
					onClose={() => setOpenNewEvent(false)}
					getEventList={getEventList}
					isEdit={isEdit}
					eventObj={
						eventList
							?.filter((item) => (currentTab === 0 ? !isPastEvent(item.eventDate) : isPastEvent(item.eventDate)))
							?.filter((item, idx) => idx === selectedSlide)?.[0]
					}
				/>
			</Box>
		</>
	);
};

export default EventDashboard;
